import {createSlice} from '@reduxjs/toolkit';

import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    verifiedIngredients: [],
    pendingIngredients: [],
    verifyIndex: -1,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};

const slice = createSlice({
    name: 'ingredients',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setVerifyIndex(state, action){
            state.verifyIndex = action.payload;
        },

        // GET PRODUCTS
        getVerifiedIngredientsSuccess(state, action) {
            state.isLoading = false;
            state.verifiedIngredients = action.payload;
        },

        getPendingIngredientsSuccess(state, action) {
            state.isLoading = false;
            state.pendingIngredients = action.payload;
        },

        verifyIngredientSuccess(state, action) {
            console.log('updating verified list');
            const ingredient = action.payload;
            const {id: verifiedIngredientId} = ingredient
            state.verifiedIngredients = [...state.verifiedIngredients, action.payload];
            const updatedPendingIngredients = state.pendingIngredients.filter((ingredient) => ingredient.id !== verifiedIngredientId);
            console.log(updatedPendingIngredients);
            state.pendingIngredients = updatedPendingIngredients;
        },


        // GET PRODUCT
        getProductSuccess(state, action) {
            state.isLoading = false;
            state.recipes = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        sortByProducts(state, action) {
            state.sortBy = action.payload;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    sortByProducts,
    filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getVerifiedIngredients(getIngredients) {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getIngredients(null, 1);
            dispatch(slice.actions.getVerifiedIngredientsSuccess(response));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPendingIngredients(getIngredients) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getIngredients(1, null);
            dispatch(slice.actions.getPendingIngredientsSuccess(response));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setVerifyIngredient(verifyIngredient, ingredient, index) {
    return async () => {
        console.log(ingredient)
        dispatch(slice.actions.setVerifyIndex(index));
        try {
            await verifyIngredient(ingredient);
            dispatch(slice.actions.verifyIngredientSuccess(ingredient));
        } catch (error) {
            console.error(error);
        }
        dispatch(slice.actions.setVerifyIndex(-1));
    };
}