// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components'
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
        analytics: path(ROOTS_DASHBOARD, '/analytics'),
    },
    constants: {
        root: path(ROOTS_DASHBOARD, '/constants'),
        all: path(ROOTS_DASHBOARD, '/constants/all')
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        // cards: path(ROOTS_DASHBOARD, '/user/cards'),
        active: path(ROOTS_DASHBOARD, '/user/active'),
        pending: path(ROOTS_DASHBOARD, '/user/pending'),
        newUser: path(ROOTS_DASHBOARD, '/user/new'),
        // editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
        account: path(ROOTS_DASHBOARD, '/user/account')
    },
    recipes: {
        root: path(ROOTS_DASHBOARD, '/recipes'),
        shop: path(ROOTS_DASHBOARD, '/recipes/shop'),
        live: path(ROOTS_DASHBOARD, '/recipes/live'),
        pending: path(ROOTS_DASHBOARD, '/recipes/pending'),
        newRecipe: path(ROOTS_DASHBOARD, '/recipes/new'),
    },
    ingredients: {
        root: path(ROOTS_DASHBOARD, '/ingredients'),
        live: path(ROOTS_DASHBOARD, '/ingredients/live'),
        pending: path(ROOTS_DASHBOARD, '/ingredients/pending'),
    },
    // blog: {
    //     root: path(ROOTS_DASHBOARD, '/blog'),
    //     posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    //     post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    //     postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    //     newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
    // }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
