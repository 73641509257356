import PropTypes from 'prop-types';
import {createContext, useEffect, useReducer, useState} from 'react';
import {initializeApp} from 'firebase/app';
import isString from "lodash/isString";

import {
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithCredential,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail
} from 'firebase/auth';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';

import {getFunctions, httpsCallable} from "firebase/functions";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import generator from "generate-password";


//
import {FIREBASE_API} from '../config';
import {recipesFromSnapshot} from "../utils/recipeHelper";
import {ingredientsFromSnapshot} from "../utils/ingredientHelper";

// ----------------------------------------------------------------------


const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const functions = getFunctions(firebaseApp, "europe-west3");
const functionsWest1 = getFunctions(firebaseApp, "europe-west1");

const storage = getStorage();

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    isRegistering: false,
    user: null,
    foodGroupsMap: [],
    allergensMap: null,
    foodGroups: null,
    cuisines: [],
    allergens: [],
    measurements: null,
    measurementsMap: [],
    categories: [],
    categoriesMap: []
};

const reducer = (state, action) => {
    if (action.type === 'INITIALISE') {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    }
    if (action.type === 'REGISTERING') {
        const {isRegistering} = action.payload;
        return {
            ...state,
            isRegistering,
        };
    }
    if (action.type === 'UNAUTHENTICATED') {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    }
    if (action.type === 'CONSTANTS') {
        const {
            foodGroupsMap,
            allergensMap,
            foodGroups,
            cuisines,
            categories,
            allergens,
            measurements,
            measurementsMap
        } = action.payload;
        return {
            ...state,
            foodGroupsMap,
            allergensMap,
            foodGroups,
            cuisines,
            categories,
            allergens,
            measurements,
            measurementsMap
        };
    }

    return state;
};

const AuthContext = createContext({
    ...initialState,
    method: 'firebase',
    login: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    loginWithGoogle: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const [profile, setProfile] = useState(null);


    useEffect(
        () =>
            onAuthStateChanged(AUTH, async (firebaseUser) => {

                if (!state.isRegistering && firebaseUser) {
                    const portalRef = doc(DB, 'profiles', firebaseUser.uid);
                    const portalDocSnap = await getDoc(portalRef);
                    if (portalDocSnap.exists()) {
                        const userRef = doc(DB, 'users', firebaseUser.uid);
                        const userDocSnapshot = await getDoc(userRef);
                        setProfile(portalDocSnap.data());
                        const user = {
                            ...firebaseUser,
                            ...userDocSnapshot.data(),
                            ...portalDocSnap.data()
                        };
                        console.log(user)
                        dispatch({
                            type: 'INITIALISE',
                            payload: {isAuthenticated: true, user},
                        });
                    } else {
                        dispatch({
                            type: 'UNAUTHENTICATED',
                            payload: {isAuthenticated: false, user: null},
                        });
                        await logout();
                    }
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {isAuthenticated: false, user: null},
                    });
                }
            }),
        [dispatch]
    );

    useEffect(() => {
        getConstants().then((res) => {
            dispatch({
                type: 'CONSTANTS',
                payload: res,
            });
        });
    }, []);

    // ------- USER ------------

    const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);
    const resetPassword = (email) => sendPasswordResetEmail(AUTH, email);

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({prompt: 'select_account'});

    const loginWithGoogle = () => {
        return signInWithPopup(AUTH, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // return {
                //     'isError': false
                // }
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // return {
                //     'isError': true,
                //     'error': error
                // }
            });
    }

    const register = (email, password, firstName, lastName) =>
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
            const userRef = doc(collection(DB, 'profiles'), res.user?.uid);
            await setDoc(userRef, {
                uid: res.user?.uid,
                email,
                displayName: `${firstName} ${lastName}`,
            });
        });

    const logout = () => signOut(AUTH);

    const uploadCloudImages = async (files, path) => {
        const promises = files.map(async (file) => {
            const fileRef = ref(storage, `${path}/${file.name}`);
            const snapshot = await uploadBytes(fileRef, file, {
                contentType: 'image/jpeg'
            });
            const url = await getDownloadURL(snapshot.ref);
            return url;
        })
        const imageList = await Promise.all(promises)
        return imageList
    }

    const updateUserInfo = async (userData) => {
        try {
            const userRef = doc(DB, 'users', state.user?.uid);
            await updateDoc(userRef, {
                ...userData
            });
            state.user = {...state.user, ...userData};
            return true
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    const addPendingProfile = async (registerName, profileType) => {
        try {
            const uniqueString = generator.generate({
                length: 30,
                numbers: true,
            });
            const newUserProfile = {type: profileType, registerName}
            const pendingProfileRef = doc(collection(DB, 'pendingProfiles'), uniqueString);
            await setDoc(pendingProfileRef, newUserProfile);
            return true;
        } catch (e) {
            console.log("Error at addPendingProfile")
            console.log(e)
            return false
        }
    }

    const getPendingPortalProfiles = async () => {
        if (state.user.type !== 'admin') return;
        try {
            const querySnapshot = await getDocs(collection(DB, "pendingProfiles"));
            return querySnapshot.docs.map((doc) => {
                return {id: doc.id, ...doc.data()}
            });
        } catch (e) {
            console.log(e);
        }
    }

    const getPendingPortalProfile = async (registerId) => {
        try {
            console.log('registerid', registerId)
            const profileDoc = doc(DB, 'pendingProfiles', registerId);
            const profileSnapshot = await getDoc(profileDoc);
            if (profileSnapshot.exists()) return profileSnapshot.data();
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    const registerPendingProfile = async (pendingProfileId, displayName, email, password, profileType) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(AUTH, email, password)
            const profilesRef = doc(collection(DB, 'profiles'), userCredential.user?.uid);
            const usersRef = doc(collection(DB, 'users'), userCredential.user?.uid);
            await setDoc(profilesRef, {type: profileType,});
            await setDoc(usersRef, {
                uid: userCredential.user?.uid,
                email,
                displayName,
                followers: 0,
                onboarded: false,
            });
            await logout();
            return true;
        } catch (e) {
            console.log("Error at addPendingProfile")
            console.log(e)
            throw e;
        }
    }

    const registerPendingProfileWithGoogle = async (pendingProfileId, profileType) => {
        try {
            signInWithPopup(AUTH, provider)
                .then(async (result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const res = await signInWithCredential(AUTH, credential);
                    console.log(res);
                    // The signed-in user info.
                    const user = res.user;
                    const {isNewUser} = getAdditionalUserInfo(result)
                    if (!isNewUser) {
                        throw new Error('Account is already in use');
                    }
                    console.log('USER')
                    console.log(user);
                    const profilesRef = doc(collection(DB, 'profiles'), user.uid);
                    const usersRef = doc(collection(DB, 'users'), user.uid);
                    await setDoc(profilesRef, {type: profileType,});
                    await setDoc(usersRef, {
                        uid: user.uid,
                        email: user?.email,
                        displayName: user?.displayName,
                        followers: 0,
                        onboarded: false,
                        loginType: 'google.com'
                    });
                    await logout();
                    return true;
                }).catch((error) => {
                console.log('Error at register with google')
                console.log(error)
                return false;
            });
        } catch (e) {
            console.log("Error at addPendingProfile")
            console.log(e)
            throw e;
        }
    }

    const connectPendingProfile = async (pendingId, email, password, profileType) => {
        try {
            const userCredential = await signInWithEmailAndPassword(AUTH, email, password);
            if (userCredential.user !== undefined) {
                const profilesRef = doc(collection(DB, 'profiles'), userCredential.user?.uid);
                await setDoc(profilesRef, {type: profileType,});
                return true;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    }
    const connectPendingProfileWithGoogle = async (pendingId, profileType) => {
        dispatch({
            type: 'REGISTERING',
            payload: {isRegistering: true},
        });
        signInWithPopup(AUTH, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const res = await signInWithCredential(AUTH, credential);
                const {isNewUser} = getAdditionalUserInfo(result)
                console.log('ISNEWUSER', isNewUser)
                if (isNewUser) {
                    dispatch({
                        type: 'REGISTERING',
                        payload: {isRegistering: false},
                    });
                    await logout();
                    throw new Error('Account does not exist');
                }
                const user = res.user;
                const profilesRef = doc(collection(DB, 'profiles'), user.uid);
                const profileSnapshot = await getDoc(profilesRef);
                if (profileSnapshot.exists()) {
                    dispatch({
                        type: 'REGISTERING',
                        payload: {isRegistering: false},
                    });
                    await logout();
                    throw new Error('Account already connected');
                }
                await setDoc(profilesRef, {type: profileType,});
                dispatch({
                    type: 'REGISTERING',
                    payload: {isRegistering: false},
                });
                return true;
            }).catch((error) => {
            console.log('Error at connect with google')
            console.log(error)
            dispatch({
                type: 'REGISTERING',
                payload: {isRegistering: false},
            });
            throw error;
        });
    }

    // -------- RECIPES --------------

    const getRecipes = async (verified = true) => {
        let recipesSnapshot;
        const recipesCol = collection(DB, 'recipes');
        if (state.user.type !== 'admin' && state.user.type !== 'marketing') {
            const recipesQuery = query(recipesCol, where('verified', '==', verified), where('ownerId', '==', state?.user?.uid));
            recipesSnapshot = await getDocs(recipesQuery);
        } else {
            const recipesQuery = query(recipesCol, where('verified', '==', verified));
            recipesSnapshot = await getDocs(recipesQuery);
        }
        if (recipesSnapshot) {
            const recipes = recipesFromSnapshot(recipesSnapshot)
            return recipes
        }
        return []
    }

    const getRecipe = async (recipeId) => {
        try {
            const recipeDoc = doc(DB, 'recipes', recipeId);
            const recipeSnapshot = await getDoc(recipeDoc);
            if (recipeSnapshot.exists()) {
                return {id: recipeSnapshot.id, ...recipeSnapshot.data()};
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    const updateRecipeVerification = async (recipeId, verified) => {
        try {
            const recipeRef = doc(DB, 'recipes', recipeId);
            await updateDoc(recipeRef, {
                verified,
            });
            return true
            // const verifyRecipe = httpsCallable(functions, 'recipes-verifyRecipe');
            // const result = await verifyRecipe({id: recipeId})
            // console.log(result);
            // return result.data.status === "success"

        } catch (e) {
            console.log("error", e)
            return false;
        }
    }

    const getRecipeTags = async () => {
        const recipesTagsDoc = doc(DB, 'constants', 'recipe-tags');
        const recipeTagsSnapshot = await getDoc(recipesTagsDoc);
        if (recipeTagsSnapshot) {
            const {recipeTags} = recipeTagsSnapshot.data()
            return recipeTags;
        }
        return []
    }


    const addRecipe = async (recipe) => {
        const {
            name,
            description,
            files: croppedFiles,
            freeFrom,
            subRecipes,
            categories,
            prepMinutes,
            cookMinutes,
            cuisine,
            steps,
            servings,
        } = recipe;
        const files = croppedFiles.map((file, index) => {
            const fileName = `${name}(${index})`
            return new File([file], fileName)
        })
        const images = await uploadCloudImages(files, 'cms-recipes')
        const recipesCol = collection(DB, 'recipes');
        const formattedPrepMinutes = Number.isNaN(parseFloat(prepMinutes)) ? 0 : parseFloat(prepMinutes);
        const formattedCookMinutes = Number.isNaN(parseFloat(cookMinutes)) ? 0 : parseFloat(cookMinutes);
        const formattedServings = Number.isNaN(parseFloat(servings)) ? 1 : parseFloat(servings);
        const user = state.user;
        const newRecipe = {
            name,
            description,
            images,
            freeFrom,
            categories,
            prepMinutes: formattedPrepMinutes,
            cookMinutes: formattedCookMinutes,
            servings: formattedServings,
            subRecipes,
            steps,
            cuisine,
            rating: "5",
            authorUid: user?.uid,
            authorName: user?.displayName,
            authorProfilePic: user?.profilePic || '',
            verified: false,
            version: 0,
        }
        await addDoc(recipesCol, newRecipe);
    }

    const updateRecipe = async (recipe) => {
        const {
            id,
            name,
            description,
            files: images,
            freeFrom,
            subRecipes,
            categories,
            prepMinutes,
            cookMinutes,
            steps,
            cuisine,
            servings,
            version,
        } = recipe;
        const imagesToUpload = [];
        const alreadyUploadedImages = [];

        let newUploadedImages = [];
        // eslint-disable-next-line prefer-arrow-callback
        images.forEach(function (image) {
            if (!isString(image)) {
                imagesToUpload.push(image);
            } else alreadyUploadedImages.push(image);
        });
        if (imagesToUpload.length > 0) {
            const files = imagesToUpload.map((file, index) => {
                const fileName = `${name}(${index})`
                return new File([file], fileName)
            })
            newUploadedImages = await uploadCloudImages(files, 'cms-recipes')
        }
        console.log(newUploadedImages);

        const formattedPrepMinutes = Number.isNaN(parseFloat(prepMinutes)) ? 0 : parseFloat(prepMinutes);
        const formattedCookMinutes = Number.isNaN(parseFloat(cookMinutes)) ? 0 : parseFloat(cookMinutes);
        const formattedServings = Number.isNaN(parseFloat(servings)) ? 1 : parseFloat(servings);
        const updatedImages = [...alreadyUploadedImages, ...newUploadedImages];

        const updatedRecipe = {
            name,
            description,
            images: updatedImages,
            freeFrom,
            categories,
            prepMinutes: formattedPrepMinutes,
            cookMinutes: formattedCookMinutes,
            servings: formattedServings,
            subRecipes,
            steps,
            cuisine,
            verified: false,
            version: version + 1
        }
        const recipeRef = doc(DB, 'recipes', id);
        await updateDoc(recipeRef, updatedRecipe);
    }


    // CONSTANTS

    const getConstants = async () => {
        const constantsDoc = doc(DB, 'appData', 'constants');
        const constantsSnapshot = await getDoc(constantsDoc);
        const {
            foodGroups,
            allergens,
            measurements,
            cuisines,
            dislikes,
            diet,
            recipeSearchSuggestions,
            ingredientSearchSuggestions,
            recipePage,
            categories
        } = constantsSnapshot.data()
        const foodGroupsMap = foodGroups.map((foodGroup) => ({value: foodGroup, label: foodGroup}))
        const allergensMap = allergens.map((allergen) => ({value: allergen, label: allergen}))
        const measurementsMap = measurements.map((measurement) => ({value: measurement, label: measurement}))
        const categoriesMap = categories.map((category) => ({value: category, label: category}))
        return {
            foodGroupsMap,
            allergensMap,
            foodGroups,
            allergens,
            measurements,
            measurementsMap,
            categories,
            categoriesMap,
            cuisines,
            dislikes,
            diet,
            recipeSearchSuggestions,
            ingredientSearchSuggestions,
            recipePage
        }
    }

    const updateConstants = async (constantKey, data) => {
        try {
            const constantsRef = doc(DB, 'appData', 'constants');
            await updateDoc(constantsRef, {
                [constantKey]: data,
            });
            return true;
        } catch (e) {
            console.log(e);
            return false
        }
    }

    // INGREDIENTS

    const getIngredients = async (pendingIngredientsPageNumber = null, ingredientsPageNumber = null) => {
        let ingredientsSnapshot;
        const ingredientsCol = collection(DB, 'ingredients');
        if (ingredientsPageNumber !== null) {
            const verifiedIngredientsQuery = query(ingredientsCol, where('verified', '==', true), orderBy('timestampAdded', 'asc'));
            ingredientsSnapshot = await getDocs(verifiedIngredientsQuery);
        } else if (pendingIngredientsPageNumber !== null) {
            const pendingIngredientsQuery = query(ingredientsCol, where('verified', '==', false), orderBy('timestampAdded', 'asc'));
            ingredientsSnapshot = await getDocs(pendingIngredientsQuery);
        }
        if (ingredientsSnapshot) {
            const ingredients = ingredientsFromSnapshot(ingredientsSnapshot);
            return ingredients
        }
        return [];
    }

    const searchIngredients = async (searchString,) => {
        try {
            const searchIngredients = httpsCallable(functionsWest1, 'searchIngredients');
            const results = await searchIngredients(
                {'searchString': searchString,})
            if (results.data.error === 1) return [];
            const ingredients = results.data.ingredients;
            return ingredients.map((ingredient) => {
                return {
                    label: ingredient.name,
                    value: ingredient.name,
                    name: ingredient.name,
                    foodGroup: ingredient.foodGroup,
                    possibleMeasurements: ingredient.possibleMeasurements,
                }
            })
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    const verifyIngredient = async (ingredient) => {
        try {
            console.log("Updating Ingredient...")
            console.log(ingredient)
            // TODO: Have the new tags added be stored in the constants folder, store constant doc uid, create cloud function to add recipe tags to the global tags in constants doc
            const {id, tags, foodGroup, isBrandProduct} = ingredient;
            const newIngredient = {
                tags,
                foodGroup,
                isBrandProduct: false,
                id
            }
            const verifyIngredient = httpsCallable(functions, 'ingredients-verifyIngredient');
            const result = await verifyIngredient(newIngredient)
            return result.data.status === "success"
        } catch (e) {
            console.log("Error")
            console.log(e)
            return false
        }
    }


    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'firebase',
                user: state.user,
                addPendingProfile,
                login,
                resetPassword,
                loginWithGoogle,
                register,
                logout,
                updateUserInfo,
                getRecipes,
                getRecipe,
                getIngredients,
                updateRecipeVerification,
                getRecipeTags,
                searchIngredients,
                addRecipe,
                updateRecipe,
                verifyIngredient,
                getConstants,
                updateConstants,
                uploadCloudImages,
                getPendingPortalProfiles,
                getPendingPortalProfile,
                registerPendingProfile,
                registerPendingProfileWithGoogle,
                connectPendingProfile,
                connectPendingProfileWithGoogle
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};
