import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

const initialState = {
    isLoading: false,
    retrievingData: false,
    error: null,
    liveRecipes: [],
    pendingRecipes: [],
    products: [],
    recipeTags: [],
    product: null,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null,
    },
};

const slice = createSlice({
    name: 'recipes',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startDataRetrieval(state) {
            state.retrievingData = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.retrievingData = false;
            state.error = action.payload;
        },

        // GET PRODUCTS
        getLiveRecipesSuccess(state, action) {
            state.retrievingData = false;
            state.liveRecipes = action.payload;
        },

        getPendingRecipesSuccess(state, action) {
            state.retrievingData = false;
            state.pendingRecipes = action.payload;
        },
        getRecipeTagsSuccess(state, action) {
            state.isLoading = false;
            state.recipeTags = action.payload;
        },

        refuteRecipeSuccess(state, action) {
            const recipe = action.payload;
            const {id: changedRecipeUid} = recipe
            state.isLoading = false;
            state.pendingRecipes = [...state.pendingRecipes, action.payload];
            const updatedLiveRecipes = state.liveRecipes.filter((recipe) => recipe.id !== changedRecipeUid);
            state.liveRecipes = updatedLiveRecipes;
        },

        verifyRecipeSuccess(state, action) {
            console.log('updating verified list');
            const recipe = action.payload;
            const {id: changedRecipeUid} = recipe
            state.isLoading = false;
            state.liveRecipes = [...state.liveRecipes, action.payload];
            const updatedPendingRecipes = state.pendingRecipes.filter((recipe) => recipe.id !== changedRecipeUid);
            console.log(updatedPendingRecipes);
            state.pendingRecipes = updatedPendingRecipes;
        },

        // GET PRODUCT
        getRecipesSuccess(state, action) {
            state.retrievingData = false;
            state.recipes = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        sortByProducts(state, action) {
            state.sortBy = action.payload;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const product = action.payload;
            const isEmptyCart = state.checkout.cart.length === 0;

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, product];
            } else {
                state.checkout.cart = state.checkout.cart.map((_product) => {
                    const isExisted = _product.id === product.id;
                    if (isExisted) {
                        return {
                            ..._product,
                            quantity: _product.quantity + 1,
                        };
                    }
                    return _product;
                });
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },

        increaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    increaseQuantity,
    decreaseQuantity,
    sortByProducts,
    filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLiveRecipes(getRecipes) {
    return async () => {
        dispatch(slice.actions.startDataRetrieval());
        try {
            const response = await getRecipes(true);
            dispatch(slice.actions.getLiveRecipesSuccess(response));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPendingRecipes(getRecipes) {
    return async () => {
        dispatch(slice.actions.startDataRetrieval());
        try {
            const response = await getRecipes(false);
            dispatch(slice.actions.getPendingRecipesSuccess(response));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function refuteRecipe(updateRecipeVerification, recipe) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {id: recipeId} = recipe
            await updateRecipeVerification(recipeId, false);
            dispatch(slice.actions.refuteRecipeSuccess(recipe));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function verifyRecipe(updateRecipeVerification, recipe) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {id: recipeId} = recipe
            await updateRecipeVerification(recipeId, true);
            dispatch(slice.actions.verifyRecipeSuccess(recipe));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function loadRecipeTags(getRecipeTags) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const recipeTags = await getRecipeTags();
            dispatch(slice.actions.getRecipeTagsSuccess(recipeTags));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/products/product', {
                params: {name},
            });
            dispatch(slice.actions.getRecipesSuccess(response.data.product));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
