export function ingredientsFromSnapshot(ingredientsSnapshot) {
    const ingredients = ingredientsSnapshot.docs.map((doc) => {
        const {id} = doc
        const {name, foodGroup, image, tags, isBrandProduct} = doc.data()
        return {
            id,
            name,
            tags,
            foodGroup,
            image,
            isBrandProduct,
            selected: false,
        }
    })
    return ingredients;
}
