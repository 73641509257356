// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{width: 1, height: 1}}/>;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking'),
};


const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'general',
        items: [
            {
                title: 'app',
                path: PATH_DASHBOARD.general.app,
                icon: ICONS.dashboard,
                roles: ['admin'],
            },
            {title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics, roles: ['admin'],},
            {
                title: 'user',
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                roles: ['admin'],
                children: [
                    {title: 'profile', path: PATH_DASHBOARD.user.profile},
                    // {title: 'cards', path: PATH_DASHBOARD.user.cards},
                    {title: 'active users', path: PATH_DASHBOARD.user.active},
                    {title: 'pending users', path: PATH_DASHBOARD.user.pending},
                    {title: 'create', path: PATH_DASHBOARD.user.newUser},
                    // {title: 'edit', path: PATH_DASHBOARD.user.editById},
                    {title: 'account', path: PATH_DASHBOARD.user.account},
                ],
            },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'management',
        items: [
            // MANAGEMENT : RECIPES
            {
                title: 'recipes',
                path: PATH_DASHBOARD.recipes.root,
                icon: ICONS.booking,
                roles: ['admin', 'marketing', 'content creator'],
                children: [
                    {title: 'live', path: PATH_DASHBOARD.recipes.live},
                    {title: 'pending', path: PATH_DASHBOARD.recipes.pending},
                    {title: 'create', path: PATH_DASHBOARD.recipes.newRecipe},
                ],
            },
            {
                title: 'ingredients',
                path: PATH_DASHBOARD.ingredients.root,
                icon: ICONS.mail,
                roles: ['admin', 'marketing',],
                children: [
                    {title: 'live', path: PATH_DASHBOARD.ingredients.live},
                    {title: 'pending', path: PATH_DASHBOARD.ingredients.pending},
                ],
            },
            // MANAGEMENT : BLOG
            // {
            //   title: 'blog',
            //   path: PATH_DASHBOARD.blog.root,
            //   icon: ICONS.blog,
            //   children: [
            //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
            //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
            //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
            //   ],
            // },
        ],
    },

    // APP
    // ----------------------------------------------------------------------
    {
        subheader: 'app',
        items: [
            {
                title: 'constants',
                path: PATH_DASHBOARD.constants.root,
                icon: ICONS.dashboard,
                roles: ['admin'],
                // info: (
                //     <Label variant="outlined" color="error">
                //         +32
                //     </Label>
                // ),
            },
        ],
    },
];

export default navConfig;
