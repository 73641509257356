import * as Yup from 'yup';
import {useState} from 'react';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Alert, Box, IconButton, InputAdornment, Link, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import GoogleButton from "react-google-button";
// routes
// hooks
import {Link as RouterLink} from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import {FormProvider, RHFCheckbox, RHFTextField} from '../../../components/hook-form';
import {PATH_AUTH} from "../../../routes/paths";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, loginWithGoogle } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const convertErrorMessage = (error) => {
      switch (error) {
          case 'Firebase: Error (auth/user-not-found).':
              return {
                  message: 'Invalid email or password',
                  resetForm: true
              }
          case 'Firebase: Error (auth/wrong-password).':
              return {
                  message: 'Invalid email or password',
                  resetForm: true
              }
          default:
              return {
                  message: 'Network error, please check your connection',
                  resetForm: false
              }
      }
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
        setErrorMessage('')
        await login(data.email, data.password);
    } catch (error) {
        const convertedErrorResponse = convertErrorMessage(error.message)
      if (isMountedRef.current) {
          setErrorMessage(convertedErrorResponse.message)
      }
      if (convertedErrorResponse.resetForm) reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errorMessage.length > 0 && <Alert severity="error">{errorMessage}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
         <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
         </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>

        <Box sx={{ mt: 2 }}>
      <GoogleButton
          onClick={() => {

              loginWithGoogle()
              // if (response.isError) {
              //     if (isMountedRef.current) {
              //         setError('afterSubmit', response.error);
              //     }
              // }
          }}
      />
        </Box>

    </FormProvider>
  );
}
