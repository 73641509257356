import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}

const initialState = {
    isLoading: false,
    error: null,
    mails: {byId: {}, allIds: []},
    labels: [],
    constants: {}
};

const slice = createSlice({
    name: 'constants',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        updateLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET LABELS
        getAppConstantsSuccess(state, action) {
            state.isLoading = false;
            state.constants = action.payload;
            state.labels = [
                'allergens',
                'measurements',
                'foodGroups',
                'recipePage',
                'recipeSearchSuggestions',
                'ingredientSearchSuggestions',
                'cuisines',
                'diet',
                'dislikes',
            ];
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setLoading(value) {
    return async () => {
        dispatch(slice.actions.updateLoading(value));
    }
}

export function getAppConstants(appConstants) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getAppConstantsSuccess(appConstants));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
