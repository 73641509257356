import {useState} from 'react';
// @mui
import {MenuItem, Stack} from '@mui/material';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';
import Iconify from "../../../components/Iconify";
import useSettings from "../../../hooks/useSettings";

// ----------------------------------------------------------------------

export default function ThemePopover() {
    const {themeMode, onChangeMode} = useSettings();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {bgcolor: 'action.selected'}),
                }}
            >
                <Iconify icon={themeMode === 'light' ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28}
                         height={28}/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
                }}
            >
                <Stack spacing={0.75}>
                    {['light', 'dark'].map((option, index) => {
                        const isSelected = themeMode === option;

                        return (
                            <MenuItem
                                key={option}
                                selected={isSelected}
                                onClick={() => {
                                    onChangeMode(option);
                                    handleClose();
                                }}
                            >
                                <Iconify icon={index === 0 ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28}
                                         height={28} sx={{mr: 2}}/>
                                {option}
                            </MenuItem>
                        )
                    })}
                </Stack>
            </MenuPopover>
        </>
    );
}
