import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import constantsReducer from './slices/constants';
import chatReducer from './slices/chat';
import recipesReducer from './slices/recipes';
import ingredientsReducer from './slices/ingredients';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const recipesPersistConfig = {
  key: 'recipes',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
const ingredientsPersistConfig = {
  key: 'ingredients',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  constants: constantsReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  recipes: persistReducer(recipesPersistConfig, recipesReducer),
  ingredients: persistReducer(ingredientsPersistConfig, ingredientsReducer),
});

export { rootPersistConfig, rootReducer };
