export function recipesFromSnapshot(snapshot) {
    return snapshot.docs.map((doc) => {
        const {id} = doc
        const {
            name,
            description,
            images,
            prepMinutes,
            cookMinutes,
            servings,
            categories,
            steps,
            subRecipes,
            freeFrom,
            cuisine,
            version,
        } = doc.data()
        return {
            id,
            name,
            description,
            images,
            prepMinutes,
            cookMinutes,
            servings,
            categories,
            steps,
            subRecipes,
            freeFrom,
            cuisine,
            version
        }
    })
}
